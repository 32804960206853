@import '../../../styles/settings';
@import '../../../styles/cas/mixins';
@import '../../../Magento_GroupedProduct/styles/view-toggle';
@import '../../../Magento_Catalog/styles/product-grid-list';
@import '../../../Magento_Catalog/styles/product-pricing';

.catalogsearch-result-index {
    .main-content {
        max-width: $global-width;
        margin-left: auto;
        margin-right: auto;
    }
}


.algolia-infos.toolbar-wrapper {
    background: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 1rem;
    color: $dark-gray;
    font-size: rem-calc(16);
}

.ais-SearchBox {
    position: relative;
    &:before {
        position: absolute;
        content: "\e941";
        font-family: '#{$icomoon-font-family}';
        color: $ui-gray;
        display: inline-block;
        left: 15px;
        top: 12px;
    }
}
.ais-SearchBox-input {
    height: auto;
    width: 100%;
    padding-left: rem-calc(42);
    background: transparent;
}
.ais-SearchBox-reset {
    top: 5px;
    right: 5px;
    .ais-SearchBox-resetIcon {
        fill: $medium-gray;
    }
    &:hover {
        .ais-SearchBox-resetIcon {
            fill: get-color(secondary);
        }
    }
}
.ais-Hits-item {
    text-indent: 0;
    text-align: left;
    &:before {
        display: none;
    }
    mark {
        background-color: rgba(get-color('light-yellow'), .75);
    }
}


.product-list-image img {
    width: inherit;
}

.ais-SortBy-select, .ais-HitsPerPage-select {
    margin: 0;
}
.no-res-page {
    margin-bottom: 5rem;
}
.brand-wrapper {
    .brand-list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        li {
            margin: 1rem 0.5rem;
            &:before {
                display: none;
            }
            a {
                padding: 0.5rem 2rem;
                font-size: rem-calc(20);
                background: get-color(primary);
                color: $white;
                text-shadow: $global-text-shadow;
                box-shadow: $global-box-shadow;
                &:hover {
                    text-decoration: none;
                    background-color: #fd8524;
                }
            }
        }

    }
}

@media screen and #{breakpoint(medium)} {
    .brand-wrapper {
        .brand-list {
            li {
                margin: 0.5rem;
            }
        }
    }
}
