//--- Product pricing
.our-price {
    margin: .5rem 0 0;
}

.price {
    &-box {
        display: inline-block;
        vertical-align: baseline;
    }
    &-label {
        font-size: rem-calc(17);
        font-style: italic;
        display: block;
    }
    &-final_price {
        .price-wrapper {
            font-size: rem-calc(26);
            color: get-color('orange');
            font-weight: 700;
            font-family: $sec-font;
        }
    }
    &-tier_price {
        .price-wrapper {
            font-weight: 700;
        }
    }
    &-msrp {
        font-size: rem-calc(15);
        vertical-align: middle;
    }
    &-saving {
        margin: 0 0 1.25rem;
    }
}

.product-info-price {
    .price {
        &-final_price {
            .price-wrapper {
                font-size: rem-calc(30);
            }
        }
    }
}

.prices-tier-wrapper {
    padding-right: 0;
}

.product-store-credit-discount {
    border-top: $global-border;
    margin-top: 1.25rem;
    padding-top: .75rem;
}

.prices-tier.items {
    border: $global-border;
    border-collapse: initial;
    border-spacing: 0;
    margin: 1.5rem 0 0;
    max-width: rem-calc(300);
    tbody {
        th, td {
            padding: .5rem;
    }
    tr {
        &:nth-child(even) {
                background: $white;
            }
        }
        &:first-child {
            th {
                border-top-left-radius: $global-radius;
            }
            td {
                border-top-right-radius: $global-radius;
            }
        }
        &:last-child {
            th {
                border-bottom-left-radius: $global-radius;
            }
            td {
                border-bottom-right-radius: $global-radius;
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .price-box {
        margin-left: 0;
    }
    .product-store-credit-discount {
        padding-top: 1rem;
        margin-top: 1rem;
    }

}

@media screen and #{breakpoint(xlarge)} {
    .product-info-price {
        .price {
            &-saving {
                display: inline-block;
                margin-left: 1rem;
            }
        }
    }
}
