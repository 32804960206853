//--- View Toggle
%valign-middle {
  vertical-align: middle;
}

.ko-product {
    .toolbar-wrapper.row{
        padding: 1rem;
        margin-bottom: 1.5rem;
    }
}

.toolbar-wrapper {
    border-bottom: 3px solid get-color(secondary);
    padding-bottom: 1rem;
  .toolbar-label {
    @extend %valign-middle;
    margin-right: 1rem;
    font-weight: 600;
  }
  .toggle-switch {
    @extend %valign-middle;
    color: $ui-gray;
    font-size: rem-calc(26);
    @include disable-mouse-outline;
    cursor: pointer;
    margin: 0 .5rem;
    &:hover, &.active {
      color: get-color(secondary);
    }
    &.last{
      margin-right: 0;
    }
  }
    .limiter-label {
        display: inline;
    }
}

