//--- Printer Associated Product List

.printer-product {
    &-list {
        margin: 1rem auto 2rem;
    }
}

.popular-list-wrapper {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
    &.printer {
        .product-list-cell {
            padding: 1rem;
        }
    }
}

.product-list {
    &-wrapper {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }
    &-image {
        text-align: center;
        margin: 0 auto;
        position: relative;
        img {
            width: 200px;
        }
        .xl-tooltip{
            position: absolute;
            bottom: rem-calc(10);
            left: 0;
        }
        .xl-badge {
            cursor: help;
            background: $ui-light-gray;
            padding: rem-calc(6);
            display: block;
            border-radius: 50%;
            width:rem-calc(36);
            height: rem-calc(36);
            box-shadow: 1px 1px 2px 0 rgba(get-color('midnight'), 0.3);
            &:before {
                content: url('../../images/icons/high-yield.svg');
                width: rem-calc(24);
                height: rem-calc(24);
                display: block;
                filter: invert(1) brightness(0.35);
            }
            &:hover {
                + .xl-tooltip-content {
                    visibility: visible;
                }
            }
        }
        .xl-tooltip-content {
            visibility: hidden;
            color: $white;
            position: absolute;
            z-index: 1;
            width: rem-calc(200);
            font-size: rem-calc(15);
            background: get-color('midnight');
            padding: rem-calc(10);
            border-radius: $global-radius;
            bottom: 0;
            left: rem-calc(-85);
            margin-bottom: rem-calc(45);
            box-shadow: $global-box-shadow;
            &:before{
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-top-color: get-color('midnight');
                position: absolute;
                right: 50%;
                bottom: -20px;
                margin-right: -10px;
                z-index: 3;
            }
        }
    }
}

.items-in-cart {
    margin: .75rem .5rem 0 0;
    text-align: right;
}

//--- Add to Cart Box
.atc {
    &-qty {
        width: auto;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        margin-top: 0;
        &-wrapper {
            flex: 0 0 auto;
            max-width: 100%;
        }
        &-num {
            width: 78px;
            margin: 0;
        }
    }
    &-button {
        width: 100%;
        padding: rem-calc(14 0);
        &-wrapper {
            margin-left: 1rem;
        }
    }
}

.list-view, .grid-view, .small-grid-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .product-list-cell {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
        padding: 2rem 1rem 3rem;
        border-bottom: 1px dotted $light-gray;
    }
    .product-list-col {
        margin-bottom: 1rem;
        .review-summary-actions {
            display: inline-block;
        }
        .product-title {
            margin-top: 1rem;
            &.pop-printers {
                display: inline-block;
                margin-top: 1rem;
            }
        }
    }
    .product-list-details {
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        .price-saving {
            font-size: rem-calc(15);
        }
        .grid-review {
            margin-bottom: 1rem;
        }
    }
    .catalog-add-cart-form {
        position: relative;
        .items-in-cart {
            position: absolute;
            bottom: -2.25rem;
            right: 0;
        }
    }
}

.list-view {
    .product-list-cell {
        padding: 1.5rem 1rem;
        &.last {
            border-bottom: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}



.product-carousel {
    &-wrapper {
        margin: 4rem 0 5rem;
    }
    .slick-slider{
        display: flex;
        padding: 0 1.5rem;
    }
    .slick-list {
        display: flex;
    }
    .slick-track {
        display: flex;
    }
    .grid-view {
        .product-list-cell {
            display: flex !important;
            flex-direction: column;
            border: 0;
            margin: 0 1rem;
            max-width: 300px;
            min-width: 300px;
            justify-content: space-between;
            min-height: rem-calc(520);
        }
        .product-image-photo {
            margin: 0 auto 1rem;
        }
    }
    .product-title {
        position: relative;
        font-size: rem-calc(15);
        height: rem-calc(63);
        overflow: hidden;
        &:after {
            content: "";
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 40%;
            height: rem-calc(21);
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        }
    }
    .review-summary {
        font-size: rem-calc(15);
        margin-top: 0.25rem;
    }
    .price-final_price .price-wrapper {
        font-size: rem-calc(25);
    }
    .price-box {
        margin-bottom: .5rem;
    }
    .atc-box {
        margin-top: 1rem;
    }
}

@media screen and #{breakpoint(medium)} {
    .list-view, .grid-view, .small-grid-view {
        .product-list-cell{
            margin: 1rem 0.5rem 1rem;
            justify-content: space-between;
        }
        .atc {
            &-qty {
                height: rem-calc(40);
            }
            &-button {
                padding: rem-calc(10 0);
            }
        }
    }
    .list-view {
        .product-list-cell {
            flex-direction: row;
        }
        .product-list-image {
            .product-image-container {
                max-width: 100%;
                margin: 0 auto;
            }
            img {
                max-width: 100%;
            }
         }
        .product-list-top {
            flex-direction: column;
            max-width: 65%;
            margin-top: 0.5rem;
            margin-right: 1rem;
            .product-title {
                margin-top: 0;
            }
        }
        .product-list-image {
            float: left;
            max-width: 20%;
            margin-right: 1rem;
        }
        .product-list-col {
            float: left;
            max-width: calc(80% - 1rem);
        }
        .auto-spacer{
            display: none;
        }
        .product-list-details {
            margin-top: 0.5rem;
        }
    }
    .product-carousel {
        .grid-view, .small-grid-view {
            .product-list-cell.last{
                border: 0;
            }
        }
    }
    .list-view {
        .product-list-cell {
            margin-top: 0;
            padding-bottom: 3rem;
            padding-top: 1rem;
        }
    }
    .grid-view, .small-grid-view {
        .product-list-image {
            img {
                max-width: 150px;
            }
        }
        .product-list-cell {
            border: 1px dotted $light-gray;
            border-radius: $global-radius;
            float: left;
            &.last {
                border: 1px dotted $light-gray;
            }
        }
        .product-list-top {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
        }
    }
    .grid-view {
        .product-list-cell {
            max-width: calc(50% - 1rem);
        }
    }
    .small-grid-view {
        .product-list-cell {
            max-width: calc(33.33% - 1rem);
        }
        .atc {
            &-qty {
                width: 100%;
                margin: 0 0 1rem;
                &-wrapper{
                    min-width: 100%;
                }
            }
            &-button {
                margin-left: 0;
                width: 100%;
                padding: rem-calc(14 10);
                &-wrapper{
                    margin: 0;
                    min-width: 100%;
                }
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    .list-view {
        .product-list-cell{
            justify-content: space-between;
        }
        .product-list-image {
            margin-right: 2rem;
        }
        .product-list-col {
            max-width: calc(80% - 2rem);
        }
        .product-list-top {
            max-width: 70%;
            margin-right: 2rem;
        }
        .atc {
             &-button {
                 width: 100%;
                 padding: rem-calc(10 0);
             }
         }
    }
    .grid-view {
        .product-list-cell {
            max-width: calc(33.33% - 1rem);
        }
    }
    .small-grid-view {
        .product-list-cell {
            max-width: calc(25% - 1rem);
        }
        .atc {
            &-button {
                padding: rem-calc(10 0);
            }
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .small-grid-view {
        .atc {
            &-qty {
                width: auto;
                margin: 0;
                &-wrapper{
                    min-width: initial;
                }
            }
            &-button {
                &-wrapper{
                    margin-left: 1rem;
                    min-width: initial;
                }
            }
        }
    }

}
