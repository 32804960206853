.toolbar-wrapper .toggle-switch, .toolbar-wrapper .toolbar-label {
  vertical-align: middle;
}

.ko-product .toolbar-wrapper.row {
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.toolbar-wrapper {
  border-bottom: 3px solid #559a13;
  padding-bottom: 1rem;
}
.toolbar-wrapper .toolbar-label {
  margin-right: 1rem;
  font-weight: 600;
}
.toolbar-wrapper .toggle-switch {
  color: #9a9da6;
  font-size: 1.625rem;
  cursor: pointer;
  margin: 0 0.5rem;
}
[data-whatinput=mouse] .toolbar-wrapper .toggle-switch {
  outline: 0;
}
.toolbar-wrapper .toggle-switch:hover, .toolbar-wrapper .toggle-switch.active {
  color: #559a13;
}
.toolbar-wrapper .toggle-switch.last {
  margin-right: 0;
}
.toolbar-wrapper .limiter-label {
  display: inline;
}

.printer-product-list {
  margin: 1rem auto 2rem;
}

.popular-list-wrapper {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.popular-list-wrapper.printer .product-list-cell {
  padding: 1rem;
}

.product-list-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.product-list-image {
  text-align: center;
  margin: 0 auto;
  position: relative;
}
.product-list-image img {
  width: 200px;
}
.product-list-image .xl-tooltip {
  position: absolute;
  bottom: 0.625rem;
  left: 0;
}
.product-list-image .xl-badge {
  cursor: help;
  background: #b3b5b9;
  padding: 0.375rem;
  display: block;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  box-shadow: 1px 1px 2px 0 rgba(22, 45, 57, 0.3);
}
.product-list-image .xl-badge:before {
  content: url("../../images/icons/high-yield.svg");
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  filter: invert(1) brightness(0.35);
}
.product-list-image .xl-badge:hover + .xl-tooltip-content {
  visibility: visible;
}
.product-list-image .xl-tooltip-content {
  visibility: hidden;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  width: 12.5rem;
  font-size: 0.9375rem;
  background: #162d39;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  bottom: 0;
  left: -5.3125rem;
  margin-bottom: 2.8125rem;
  box-shadow: 0px 2px 4px 0 rgba(22, 45, 57, 0.2);
}
.product-list-image .xl-tooltip-content:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #162d39;
  position: absolute;
  right: 50%;
  bottom: -20px;
  margin-right: -10px;
  z-index: 3;
}

.items-in-cart {
  margin: 0.75rem 0.5rem 0 0;
  text-align: right;
}

.atc-qty {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-top: 0;
}
.atc-qty-wrapper {
  flex: 0 0 auto;
  max-width: 100%;
}
.atc-qty-num {
  width: 78px;
  margin: 0;
}
.atc-button {
  width: 100%;
  padding: 0.875rem 0;
}
.atc-button-wrapper {
  margin-left: 1rem;
}

.list-view, .grid-view, .small-grid-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.list-view .product-list-cell, .grid-view .product-list-cell, .small-grid-view .product-list-cell {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  padding: 2rem 1rem 3rem;
  border-bottom: 1px dotted #ccc;
}
.list-view .product-list-col, .grid-view .product-list-col, .small-grid-view .product-list-col {
  margin-bottom: 1rem;
}
.list-view .product-list-col .review-summary-actions, .grid-view .product-list-col .review-summary-actions, .small-grid-view .product-list-col .review-summary-actions {
  display: inline-block;
}
.list-view .product-list-col .product-title, .grid-view .product-list-col .product-title, .small-grid-view .product-list-col .product-title {
  margin-top: 1rem;
}
.list-view .product-list-col .product-title.pop-printers, .grid-view .product-list-col .product-title.pop-printers, .small-grid-view .product-list-col .product-title.pop-printers {
  display: inline-block;
  margin-top: 1rem;
}
.list-view .product-list-details, .grid-view .product-list-details, .small-grid-view .product-list-details {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.list-view .product-list-details .price-saving, .grid-view .product-list-details .price-saving, .small-grid-view .product-list-details .price-saving {
  font-size: 0.9375rem;
}
.list-view .product-list-details .grid-review, .grid-view .product-list-details .grid-review, .small-grid-view .product-list-details .grid-review {
  margin-bottom: 1rem;
}
.list-view .catalog-add-cart-form, .grid-view .catalog-add-cart-form, .small-grid-view .catalog-add-cart-form {
  position: relative;
}
.list-view .catalog-add-cart-form .items-in-cart, .grid-view .catalog-add-cart-form .items-in-cart, .small-grid-view .catalog-add-cart-form .items-in-cart {
  position: absolute;
  bottom: -2.25rem;
  right: 0;
}

.list-view .product-list-cell {
  padding: 1.5rem 1rem;
}
.list-view .product-list-cell.last {
  border-bottom: 0;
}
.list-view .product-list-cell:last-child {
  border-bottom: 0;
}

.product-carousel-wrapper {
  margin: 4rem 0 5rem;
}
.product-carousel .slick-slider {
  display: flex;
  padding: 0 1.5rem;
}
.product-carousel .slick-list {
  display: flex;
}
.product-carousel .slick-track {
  display: flex;
}
.product-carousel .grid-view .product-list-cell {
  display: flex !important;
  flex-direction: column;
  border: 0;
  margin: 0 1rem;
  max-width: 300px;
  min-width: 300px;
  justify-content: space-between;
  min-height: 32.5rem;
}
.product-carousel .grid-view .product-image-photo {
  margin: 0 auto 1rem;
}
.product-carousel .product-title {
  position: relative;
  font-size: 0.9375rem;
  height: 3.9375rem;
  overflow: hidden;
}
.product-carousel .product-title:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 1.3125rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 50%);
}
.product-carousel .review-summary {
  font-size: 0.9375rem;
  margin-top: 0.25rem;
}
.product-carousel .price-final_price .price-wrapper {
  font-size: 1.5625rem;
}
.product-carousel .price-box {
  margin-bottom: 0.5rem;
}
.product-carousel .atc-box {
  margin-top: 1rem;
}

@media screen and (min-width: 48em) {
  .list-view .product-list-cell, .grid-view .product-list-cell, .small-grid-view .product-list-cell {
    margin: 1rem 0.5rem 1rem;
    justify-content: space-between;
  }
  .list-view .atc-qty, .grid-view .atc-qty, .small-grid-view .atc-qty {
    height: 2.5rem;
  }
  .list-view .atc-button, .grid-view .atc-button, .small-grid-view .atc-button {
    padding: 0.625rem 0;
  }
  .list-view .product-list-cell {
    flex-direction: row;
  }
  .list-view .product-list-image .product-image-container {
    max-width: 100%;
    margin: 0 auto;
  }
  .list-view .product-list-image img {
    max-width: 100%;
  }
  .list-view .product-list-top {
    flex-direction: column;
    max-width: 65%;
    margin-top: 0.5rem;
    margin-right: 1rem;
  }
  .list-view .product-list-top .product-title {
    margin-top: 0;
  }
  .list-view .product-list-image {
    float: left;
    max-width: 20%;
    margin-right: 1rem;
  }
  .list-view .product-list-col {
    float: left;
    max-width: calc(80% - 1rem);
  }
  .list-view .auto-spacer {
    display: none;
  }
  .list-view .product-list-details {
    margin-top: 0.5rem;
  }
  .product-carousel .grid-view .product-list-cell.last, .product-carousel .small-grid-view .product-list-cell.last {
    border: 0;
  }
  .list-view .product-list-cell {
    margin-top: 0;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }
  .grid-view .product-list-image img, .small-grid-view .product-list-image img {
    max-width: 150px;
  }
  .grid-view .product-list-cell, .small-grid-view .product-list-cell {
    border: 1px dotted #ccc;
    border-radius: 0.3125rem;
    float: left;
  }
  .grid-view .product-list-cell.last, .small-grid-view .product-list-cell.last {
    border: 1px dotted #ccc;
  }
  .grid-view .product-list-top, .small-grid-view .product-list-top {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }
  .grid-view .product-list-cell {
    max-width: calc(50% - 1rem);
  }
  .small-grid-view .product-list-cell {
    max-width: calc(33.33% - 1rem);
  }
  .small-grid-view .atc-qty {
    width: 100%;
    margin: 0 0 1rem;
  }
  .small-grid-view .atc-qty-wrapper {
    min-width: 100%;
  }
  .small-grid-view .atc-button {
    margin-left: 0;
    width: 100%;
    padding: 0.875rem 0.625rem;
  }
  .small-grid-view .atc-button-wrapper {
    margin: 0;
    min-width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .list-view .product-list-cell {
    justify-content: space-between;
  }
  .list-view .product-list-image {
    margin-right: 2rem;
  }
  .list-view .product-list-col {
    max-width: calc(80% - 2rem);
  }
  .list-view .product-list-top {
    max-width: 70%;
    margin-right: 2rem;
  }
  .list-view .atc-button {
    width: 100%;
    padding: 0.625rem 0;
  }
  .grid-view .product-list-cell {
    max-width: calc(33.33% - 1rem);
  }
  .small-grid-view .product-list-cell {
    max-width: calc(25% - 1rem);
  }
  .small-grid-view .atc-button {
    padding: 0.625rem 0;
  }
}
@media screen and (min-width: 75em) {
  .small-grid-view .atc-qty {
    width: auto;
    margin: 0;
  }
  .small-grid-view .atc-qty-wrapper {
    min-width: initial;
  }
  .small-grid-view .atc-button-wrapper {
    margin-left: 1rem;
    min-width: initial;
  }
}
.our-price {
  margin: 0.5rem 0 0;
}

.price-box {
  display: inline-block;
  vertical-align: baseline;
}
.price-label {
  font-size: 1.0625rem;
  font-style: italic;
  display: block;
}
.price-final_price .price-wrapper {
  font-size: 1.625rem;
  color: #ee4530;
  font-weight: 700;
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
}
.price-tier_price .price-wrapper {
  font-weight: 700;
}
.price-msrp {
  font-size: 0.9375rem;
  vertical-align: middle;
}
.price-saving {
  margin: 0 0 1.25rem;
}

.product-info-price .price-final_price .price-wrapper {
  font-size: 1.875rem;
}

.prices-tier-wrapper {
  padding-right: 0;
}

.product-store-credit-discount {
  border-top: 1px solid #ccc;
  margin-top: 1.25rem;
  padding-top: 0.75rem;
}

.prices-tier.items {
  border: 1px solid #ccc;
  border-collapse: initial;
  border-spacing: 0;
  margin: 1.5rem 0 0;
  max-width: 18.75rem;
}
.prices-tier.items tbody th, .prices-tier.items tbody td {
  padding: 0.5rem;
}
.prices-tier.items tbody tr:nth-child(even) {
  background: #ffffff;
}
.prices-tier.items tbody:first-child th {
  border-top-left-radius: 0.3125rem;
}
.prices-tier.items tbody:first-child td {
  border-top-right-radius: 0.3125rem;
}
.prices-tier.items tbody:last-child th {
  border-bottom-left-radius: 0.3125rem;
}
.prices-tier.items tbody:last-child td {
  border-bottom-right-radius: 0.3125rem;
}

@media screen and (min-width: 48em) {
  .price-box {
    margin-left: 0;
  }
  .product-store-credit-discount {
    padding-top: 1rem;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 75em) {
  .product-info-price .price-saving {
    display: inline-block;
    margin-left: 1rem;
  }
}
.catalogsearch-result-index .main-content {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

.algolia-infos.toolbar-wrapper {
  background: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem;
  color: #333333;
  font-size: 1rem;
}

.ais-SearchBox {
  position: relative;
}
.ais-SearchBox:before {
  position: absolute;
  content: "\e941";
  font-family: "TI-Icons";
  color: #9a9da6;
  display: inline-block;
  left: 15px;
  top: 12px;
}

.ais-SearchBox-input {
  height: auto;
  width: 100%;
  padding-left: 2.625rem;
  background: transparent;
}

.ais-SearchBox-reset {
  top: 5px;
  right: 5px;
}
.ais-SearchBox-reset .ais-SearchBox-resetIcon {
  fill: #676d7f;
}
.ais-SearchBox-reset:hover .ais-SearchBox-resetIcon {
  fill: #559a13;
}

.ais-Hits-item {
  text-indent: 0;
  text-align: left;
}
.ais-Hits-item:before {
  display: none;
}
.ais-Hits-item mark {
  background-color: rgba(250, 215, 28, 0.75);
}

.product-list-image img {
  width: inherit;
}

.ais-SortBy-select, .ais-HitsPerPage-select {
  margin: 0;
}

.no-res-page {
  margin-bottom: 5rem;
}

.brand-wrapper .brand-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
}
.brand-wrapper .brand-list li {
  margin: 1rem 0.5rem;
}
.brand-wrapper .brand-list li:before {
  display: none;
}
.brand-wrapper .brand-list li a {
  padding: 0.5rem 2rem;
  font-size: 1.25rem;
  background: #e67921;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 2px 4px 0 rgba(22, 45, 57, 0.2);
}
.brand-wrapper .brand-list li a:hover {
  text-decoration: none;
  background-color: #fd8524;
}

@media screen and (min-width: 48em) {
  .brand-wrapper .brand-list li {
    margin: 0.5rem;
  }
}